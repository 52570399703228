module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NL3VJS","includeInDevelopment":true,"delayExcludedPaths":[],"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ready2order website","short_name":"r2o","start_url":"https://ready2order.com/at/","lang":"de-AT","background_color":"#0A2433","theme_color":"#0A2433","display":"standalone","description":"Finden Sie Ihre Registrierkasse online bei ready2order, dem Testsieger aus Österreich ✅ Exzellenter Kundenservice ✅ über 10.000 Installationen.","legacy":false,"localize":[{"start_url":"https://ready2order.com/de/","lang":"de-DE"},{"start_url":"https://ready2order.com/en/","lang":"en-US","description":"Find out more about the award winning cash register from Austria. ✅ Excellent customer service ✅ over 10.000 customers."}],"icons":[{"src":"/favicons/android-icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/apple-icon-180x180.png","sizes":"180x180","type":"image/png"},{"src":"/favicons/apple-icon-152x152.png","sizes":"152x152","type":"image/png"},{"src":"/favicons/apple-icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/favicons/apple-icon-120x120.png","sizes":"120x120","type":"image/png"},{"src":"/favicons/apple-icon-114x114.png","sizes":"114x114","type":"image/png"},{"src":"/favicons/favicon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/favicons/apple-icon-76x76.png","sizes":"76x76","type":"image/png"},{"src":"/favicons/apple-icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/favicons/apple-icon-60x60.png","sizes":"60x60","type":"image/png"},{"src":"/favicons/apple-icon-57x57.png","sizes":"57x57","type":"image/png"},{"src":"/favicons/android-icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"/favicons/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"/favicons/favicon-16x16.png","sizes":"16x16","type":"image/png"}],"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
